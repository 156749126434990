import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

export default {
  name: "batteryModel",
  mixins: [mixins],
  components: {},
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      columns: [{
        label: '电池型号名称',
        prop: 'name'
      }, {
        label: '标准电压',
        prop: 'standardVoltage'
      }, {
        label: '标准容量',
        prop: 'standardCapacity'
      }, {
        label: '尺寸大小',
        prop: 'modelSize'
      }, {
        label: '最小电压',
        prop: 'minVoltage'
      }, {
        label: '最大电压',
        prop: 'maxVoltage'
      }, {
        label: '最大行驶距离',
        prop: 'maxMileage'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }],
      topButtons: [{
        name: '新增',
        code: 'batteryModel_add',
        type: 'primary',
        click: this.add,
        permissions: ['batteryModel_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'batteryModel_del',
        type: 'danger',
        click: this.Del,
        permissions: ['batteryModel_del']
      }, {
        name: '编辑',
        code: 'batteryModel_edit',
        click: this.Edit,
        permissions: ['batteryModel_edit']
      }],
      searchItems: [{
        prop: 'name',
        label: '电池型号'
      }]
    };
  },
  methods: {
    add() {
      this.$router.push('/batteryModelAdd');
    },
    Edit(row) {
      this.$router.push({
        path: '/batteryModelAdd',
        query: {
          id: row.id
        }
      });
    },
    Del(row) {
      ElMessageBox.confirm('确认要删除该条记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.batteryModel.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};